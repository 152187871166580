@font-face {
  font-family: "abel";
  src: url("../fonts/abel.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  color: #131313;
  font-size: 20px;
  font-family: "abel", "Helvetica Neue", "Arial", sans-serif;
  line-height: 1.5;
}

body {
  background-color: #ececec;
  margin: 0;
}

main {
  display: grid;
  height: 100%;
  width: 100%;
  grid-gap: 2rem;
  grid-template-columns: 1fr 40ch 60ch 1fr;
}

code {
  font-family: "Menlo", "Consolas", monospace;
  font-size: 4rem;
  line-height: 1;
  margin-top: 2rem;
}

h1 {
  font-size: 8rem;
  margin: 0;
}

h2 {
  font-size: 2rem;
  margin: 0;
  line-height: 1;
  text-align: right;
}

h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  margin-top: 0;
  line-height: 1;
}

p {
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-top: 0;
  max-width: 64ch;
}

p:last-of-type {
  margin-bottom: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  display: block;
}

footer {
  color: #ececec;
  grid-column: 1/5;
  grid-row: 6;
  padding: 1rem;
  text-align: center;
  z-index: 1;
}

.stars-container {
  align-items: center;
  display: flex;
  filter: grayscale(1);
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.stars-container-top {
  grid-row: 1 / 3;
  grid-column: 1 / 5;
}

.stars-container-bottom {
  grid-row: 6;
  grid-column: 1 / 5;
}

.stars {
  animation: stargaze 60s ease infinite;
  background-color: #000;
  background-image: url("../images/icons-bg.png");
  background-position: center;
  background-size: 15%;
  height: 200%;
  filter: invert();
  position: absolute;
  width: 200%;
}

.wordmark {
  align-self: center;
  color: #ececec;
  grid-column: 2/3;
  grid-row: 1/3;
  justify-self: center;
  left: 4px;
  position: relative;
  z-index: 1;
}

.name {
  color: #ececec;
  grid-column: 3/4;
  grid-row: 2/3;
  left: -8px;
  line-height: 1;
  position: relative;
  top: 1px;
  z-index: 1;
}

.heading {
  grid-column: 2/3;
}

.content {
  grid-column: 3/4;
}

.recent-project {
  display: flex;
  margin-bottom: 1rem;
  transition: .2s filter;
}

.recent-project:hover,
.recent-project:focus {
  background-color: #fff;
  cursor: pointer;
  outline: 1px solid #131313;
}

.recent-project:last-of-type {
  margin-bottom: 0;
}

.recent-project-body {
  flex: auto;
  padding: 1rem;
}

.recent-project-short-description {
  display: none;
}

.grid-item {
  position: relative;
}

.square {
  position: relative;
}

.square::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.square > * {
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.recent-project-thumbnail {
  flex: 0 0 auto;
  height: 200px;
  outline: 1px solid #131313;
  width: 200px;
}

.conctact-content {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(3, minmax(1px, 100px));
}

.contact-item {
  outline: 1px solid #131313;
}

.contact-item:hover,
.contact-item:focus {
  background-color: #fff;
}

.contact-item-inner {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-icon {
  height: 64px;
  width: 64px;
}

@keyframes stargaze {
	0% { transform: translate(0, 0); }
	12.5% { transform: translate(-25%, -25%); }
	25% { transform: translate(0, 0); }
	37.5% { transform: translate(25%, -25%); }
	50% { transform: translate(0, 0); }
	62.5% { transform: translate(25%, 25%); }
	75% { transform: translate(0, 0); }
	87.5% { transform: translate(-25%, 25%); }
	100% { transform: translate(0, 0);  }
}

@media (max-width: 1200px) {
  html {
    font-size: 18px;
  }

  main {
    grid-template-columns: 1fr 20% 60% 1fr;
  }

  .recent-project-thumbnail {
    height: 175px;
    width: 175px;
  }
}

@media (max-width: 770px) {
  html {
    font-size: 18px;
  }

  main {
    grid-gap: 1rem;
    grid-template-columns: 1px 1fr 1px;
  }

  footer,
  .stars-container-bottom {
    grid-row: 9;
    grid-column: 1 / 4;
  }

  .stars-container-top {
    grid-column: 1/4;
  }

  .stars {
    background-size: 75%;
  }

  .heading {
    text-align: left;
  }

  .wordmark,
  .name {
    grid-column: 2;
    text-align: center;
  }

  .wordmark {
    grid-row: 1;
  }

  .name {
    grid-row: 2;
  }

  .content {
    grid-column: 2;
  }

  .recent-project-thumbnail {
    max-height: 155px;
    max-width: 155px;
  }
}

@media (max-width: 500px) {
  html {
    font-size: 16px;
  }

  .stars {
    background-size: 125%;
  }

  .wordmark {
    font-size: 2rem;
  }

  .recent-project-description {
    display: none;
  }

  .recent-project-short-description {
    display: block;
  }

  .recent-project-thumbnail {
    max-height: 100px;
    max-width: 100px;
    width: 100%;
  }

  .contact-type {
    display: none;
  }
}